<template>
  <div
      class="app-content content"
      :class="[{'show-overlay': $store.state.app.shallShowOverlay}]"
  >
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <b-card no-body>
            <div class="m-2">
            </div>
            <div class="mx-2 mb-2">
              <div class="row mb-2">
                <div class="col-12">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button :to="{name: 'return-create'}" v-if="$can('create', 'returns')" variant="primary">
                      <span class="text-nowrap">Добавить Возврат</span>
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ returns.from }} по {{ returns.to }} из {{ returns.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="returns.total"
                      :per-page="returns.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <!---->
                <tr>
                  <th>#</th>
                  <th>Дата</th>
                  <th v-if="$can('read', 'users')">Покупатель</th>
                  <th>Статус</th>
                  <th>К-во</th>
                  <th>Вес (кг)</th>
                  <th v-if="$can('read', 'payments')">Сумма</th>
                  <th>Комментарий</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="clientReturn in returns.data"
                    :key="clientReturn.id"
                    :class="getOrderStatusClass(clientReturn.status)"
                    >
                  <td>
                    <router-link
                        :to="{ name: 'return-details', params: { id: clientReturn.id }}"
                        class="font-weight-bold"
                    >
                      #{{ clientReturn.id }}
                    </router-link>
                  </td>
                  <td>{{ clientReturn.created_at | formattedDate }}</td>
                  <td v-if="$can('read', 'users')" style="white-space: nowrap">
                    {{ clientReturn.user.name }} {{ clientReturn.user.surname }} <br>
                    <small>({{ clientReturn.user.company_name }})</small>
                  </td>
                  <td>{{ clientReturn.status.name }}</td>
                  <td>{{ clientReturn.qty }}</td>
                  <td>{{ clientReturn.weight / 1000 }}</td>
                  <td v-if="$can('read', 'payments')">
                    <span class="amount" v-for="(item, index) in clientReturn.sum" :key="index">
                      <span class="amount">{{ item.amount }}</span> <span class="currency">{{ item.currency }}</span>  <br/>
                    </span>
                  </td>
                  <td class="font-small-2">{{ clientReturn.comments }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            Показано с {{ returns.from }} по {{ returns.to }} из {{ returns.total }} записей</span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="returns.total"
                      :per-page="returns.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </b-card>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BButton, BPagination } from 'bootstrap-vue';

export default {
  name: 'Returns',
  components: { BCard, BButton, BPagination },
  data() {
    return {
      currentPage: 1,
      statuses: [],
      returns: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
    };
  },
  watch: {
    async currentPage(newValue) {
      this.returns.current_page = newValue;
      await this.getReturns();
    },
  },
  async mounted() {
    await this.getOrderStatuses();
    await this.getReturns();
  },
  methods: {
    async getReturns() {
      this.returns = (await this.$api.returns.list({
        ...(this.returns.current_page > 1 && { page: this.returns.current_page }),
        limit: 25,
      })).data;
    },
    async getOrderStatuses() {
      this.statuses = (await this.$api.orders.statuses()).data;
    },
    getOrderStatusClass(status) {
      if (status.id === 2) {
        return 'row-green';
      }

      if (status.id === 1) {
        return 'row-warning';
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

::v-deep .v-select {
  width: 220px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}

.row-green {
  background-color: #e6ffe6;
}

.row-warning {
  background-color: #fff3cd;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
